import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Hero.css';

const Hero = ({ onSearch }) => {
  // State to store the search query
  const [searchQuery, setSearchQuery] = useState('');

  // Function to handle the search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value); // Update the state with the input value
    onSearch(e.target.value); // Pass the search query to the parent component (Home)
  };

  // Function to handle the search button click or form submission
  const handleSearchSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    if (searchQuery) {
      console.log(`Searching for jobs with the query: "${searchQuery}"`);
      // You can integrate the search logic here
      // For example, call an API or filter job listings based on the searchQuery
    } else {
      console.log('Please enter a search query.');
    }
  };

  return (
    <div className="hero">
      <div className="filter-box">
        <div className="d-flex justify-content-between align-items-start mb-4">
          {/* <h4>Search For Jobs</h4> */}
        </div>

        <div className="row mb-3">
          {/* Form for search */}
          <form className="d-flex search-bar" onSubmit={handleSearchSubmit}>
            <input
              className="form-control me-2"
              type="search"
              placeholder="Search jobs..."
              aria-label="Search"
              value={searchQuery}
              onChange={handleSearchChange} // Call handleSearchChange on input change
            />
            <button className="btn btn-outline-success" type="submit">
              Search
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Hero;
