import React, { useEffect } from 'react';

const AdSenseComponent = () => {
   useEffect(() => {
       try {
           (window.adsbygoogle = window.adsbygoogle || []).push({});
       } catch (e) {
           console.error("AdSense error:", e);
       }
   }, []);

   return (
       <ins
           className="adsbygoogle"
           style={{ display: 'block' }}
           data-ad-client="ca-pub-3576980651786987"  // Replace with your AdSense ID
           data-ad-slot="3663647562"           // Replace with your Ad Slot ID
           data-ad-format="auto"
           data-full-width-responsive="true"
       ></ins>
   );
};

export default AdSenseComponent;
