import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth';
import './Header.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Header = (props) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [user, setUser] = useState(null);
  const auth = getAuth();
  const provider = new GoogleAuthProvider();


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 120) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Check if a user is already logged in
    auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [auth]);

  const handleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null); // Clears the user on logout
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const getInitial = () => {
    if (user?.displayName) {
      return user.displayName.charAt(0).toUpperCase();
    }
    if (user?.email) {
      return user.email.charAt(0).toUpperCase();
    }
    return '';
  };


  return (
    <header className={`navbar navbar-expand-lg fixed-top ${isScrolled ? 'scrolled' : 'hero-bg'}`}>
      <div className="container">
        {/* Company Logo and Name */}
        <Link to="/"><img src={props.logo} alt="Company Logo" className="header-logo" /></Link>
        <NavLink to="/" className="navbar-brand d-flex align-items-center">
          <b style={{ fontSize: '1.8rem' }}>{props.companyName}</b>
        </NavLink>

        {/* Responsive toggler for mobile view */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Navigation Links */}
        <div className="collapse navbar-collapse justify-content-end" id="navbarNav">

          <ul className="navbar-nav">

            <li className="nav-item">
              <NavLink to="/" className={({ isActive }) => (isActive ? "nav-link active-link" : "nav-link")}>
                <b>Apply for Jobs</b>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to="/prepare-for-interview" className={({ isActive }) => (isActive ? "nav-link active-link" : "nav-link")}>
                <b>Prepare for Interview</b>
              </NavLink>
            </li>

          </ul>

          {/* User Icon and Dropdown */}
          {user ? (
            <div className="dropdown ms-3">
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                id="userDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {getInitial()}
              </button>
              <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                <li>
                  <button onClick={handleLogout} className="dropdown-item">
                    Logout
                  </button>
                </li>
              </ul>
            </div>
          ) : (
            <button onClick={handleLogin} className="btn btn-outline-primary ms-3">
              Login
            </button>
          )}

        </div>
      </div>
    </header>
  );
};

export default Header;
