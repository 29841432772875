// src/firebaseConfig.js

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAUwOsl-0KKiWnvCq7rV1SyHI882qRv01M",
  authDomain: "computacher-job.firebaseapp.com",
  projectId: "computacher-job",
  storageBucket: "computacher-job.appspot.com",
  messagingSenderId: "334858128979",
  appId: "1:334858128979:web:8b94104a029249040180a6",
  measurementId: "G-9ZT1XGVT7T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get Firestore database instance
const db = getFirestore(app);

export { db };
