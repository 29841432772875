import React, { useEffect, useState } from 'react';
import { db } from '../../firebaseConfig'; // Firebase configuration
import { doc, getDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import './JobDetails.css'
import ReactMarkdown from 'react-markdown';

const JobDetail = () => {
  const { id } = useParams(); // Get the job ID from the URL
  const [job, setJob] = useState(null);

  useEffect(() => {
    const fetchJobDetail = async () => {
      try {
        const jobDoc = doc(db, 'jobs', id); // Fetch the specific job using its ID
        const jobData = await getDoc(jobDoc);
        if (jobData.exists()) {
          setJob(jobData.data());
        } else {
          console.log('No such job found!');
        }
      } catch (error) {
        console.error('Error fetching job details: ', error);
      }
    };

    fetchJobDetail();
  }, [id]);

  if (!job) {
    return <div>Loading job details...</div>;
  }

  return (
    <>
      {/* Top Section with Hero-like background color */}
      <div className="job-detail-hero">
        <div className="container job-header-container">
          {/* <img src={job.companyLogo} alt={`${job.company} logo`} className="company-logo" /> */}
          <h1 className="job-title">{job.title}</h1>
          <div className="company-info">
            <h6 className="company-name">{job.company}</h6>
            <p className="company-details">Location: {job.location}</p>
          </div>
        </div>
      </div>

      {/* Main Content Section */}
      <div className="container job-detail-container mt-5">
        <div className="row">
          {/* Job Description Section */}
          <div className="col-md-8">
            <div className="job-description">
              <ReactMarkdown>{job.description}</ReactMarkdown>
            </div>
          </div>

          {/* Right Section: Apply Section */}
          <div className="col-md-4 apply-section-top">
            <div className="card apply-section-card">
              <div className="card-body">
                <h5 className="card-title">Apply now</h5>
                <b><p className="card-text" style={{ color: 'rgb(233, 142, 15)', marginBottom: '10px' }}> Before you hit Apply ensure your resume reflects your brilliance!</p></b>
                <a className="btn btn-primary btn-block" href = {job.applyLink} target="_blank" rel="noopener noreferrer">Apply now</a>
                <p className="text-muted mt-3">
                  <small>Posted on: {job.jobPostTime}</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobDetail;
