import React, { useState, useEffect } from 'react';
import { db } from '../../firebaseConfig'; // Firebase configuration
import { collection, getDocs, query, orderBy } from 'firebase/firestore'; // Correct Firestore import
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment'; // Import moment.js to format time
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './Home.css';
import Hero from '../Hero/Hero';
import AdSenseComponent from '../AdSenseComponent';

const Home = () => {
  const [jobs, setJobs] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  // Fetch jobs from Firebase Firestore
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const jobCollection = collection(db, 'jobs'); // Ensure this collection call is correct
        // const jobQuery = jobCollection
        const jobQuery = query(jobCollection, orderBy('createdAt', 'desc')); // Sort by createdAt descending
        const jobSnapshot = await getDocs(jobQuery);
        const jobList = jobSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setJobs(jobList);
        // console.log("jobs...", jobList);
      } catch (error) {
        console.error('Error fetching jobs: ', error);
      }
    };

    fetchJobs();
  }, []);

  const filteredJobs = jobs.filter((job) =>
    job.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    job.skills?.some(skill => skill.toLowerCase().includes(searchQuery.toLowerCase())) ||
    job.workingTime.toLowerCase().includes(searchQuery.toLowerCase()) ||
    job.subTitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
    job.company.toLowerCase().includes(searchQuery.toLowerCase()) ||
    job.experienceLevel.toLowerCase().includes(searchQuery.toLowerCase()) ||
    job.jobPostTime.toLowerCase().includes(searchQuery.toLowerCase())
  );


  return (
    <div className="container-fluid mt-5">
      <div className="row">
        <Hero onSearch={setSearchQuery} />
        {/* Left Section: Job Cards (60% width) */}
        <div className="col-md-7 left-section">
          <div className="jobs-list">
            {filteredJobs.length > 0 ? (
              filteredJobs.map((job) => (
                <Link to={`/job/${job.id}`} key={job.id} className="card-link">
                  <div className="card mb-4">
                    <div className="card-body">
                      <h5 className="card-title">{job.title}</h5>
                      <h6 className="card-subtitle mb-2 text-muted">{job.company}</h6>

                      <div className="labels">
                        <p className="job-label">{job.subTitle}</p>
                        <p className="job-label">{job.workingTime}</p>
                        <p className="job-label">{job.experienceLevel}</p>
                      </div>

                      <p className="card-text">
                        <strong>Experience:</strong> {job.experience}
                      </p>
                      <p className="card-text">
                        <strong>Location:</strong> {job.location}
                      </p>
                      <p className="card-text">
                        <strong>Pay Range:</strong> {job.payRange} {job.currencyType}
                      </p>
                      <p className="text-muted">
                        <small>Posted on: {job.jobPostTime}</small>
                      </p>
                      <p className="posted-time text-muted">
                        {job.createdAt ? moment(job.createdAt.toDate()).fromNow() : 'Past Posting'}
                      </p>
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <p className="no-jobs-message">No jobs found</p>
            )}
          </div>

        </div>

        {/* Right Section: Additional Content (40% width) */}
        <div className="col-md-5 right-section">
          <AdSenseComponent />
        </div>
      </div>
    </div>
  );
};

export default Home;
