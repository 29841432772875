import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { Auth0Provider } from '@auth0/auth0-react';
// import firebase from 'firebase/app';
// import 'firebase/firestore';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <Auth0Provider
  //   domain="dev-7qhcu1403qswu2ql.us.auth0.com"
  //   clientId="7a7rOYfVFQBAhH9HxH1AWkRGvsj3itkP"
  //   authorizationParams={{
  //     redirect_uri: window.location.origin
  //   }}
  // >
  //   <App />
  // </Auth0Provider>
    <App />
);


// const firebaseConfig = {
//   apiKey: "AIzaSyDlj8mDzBg8pIEufDREH6FcSN9BGYl3IZM",
//   authDomain: "codexpert-f882e.firebaseapp.com",
//   databaseURL: "https://codexpert-f882e-default-rtdb.firebaseio.com",
//   projectId: "codexpert-f882e",
//   storageBucket: "codexpert-f882e.appspot.com",
//   messagingSenderId: "525026536455",
//   appId: "1:525026536455:web:29eda1abc34c61126b8ea2"
// };

// firebase.initializeApp(firebaseConfig);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
