import React, { useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// index.js or App.js
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JS bundle
import Logo from './img/logo-blue.png'
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import JobDetail from './components/JobDetails/JobDetails';
import PrepareForInterview from './components/PrepareForInterview/PrepareForInterview';
import './App.css';


function App() {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (query) => {
    setSearchQuery(query);
  };
  return (
    <Router>
      <Header companyName="CompuTeacher" logo={Logo} onSearch={handleSearch} />
      <Routes>
        <Route path="/" element={<Home searchQuery={searchQuery}/>}>
        </Route>
        <Route path="/job/:id" element={<JobDetail />}>
        </Route>
        <Route path="/prepare-for-interview" element={<PrepareForInterview />}>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
